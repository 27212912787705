$small: 360px;
$medium: 700px;
$large: 2000px;
$rustic:"../pictures/rustic.jpg";
$cpanel:"../pictures/cpanel.jpg";
$gravel:"../pictures/az_dirt.jpg";
$sun:"../pictures/HighSun.jpg";
body{
width: 100vw !important;
    display: flex;
    justify-content: center;
    .shadow{
        text-shadow:  #FC0 1px 0 10px;;
    }
    @media screen and (min-width: $medium) and (max-width:  $large) {
        .s{display: none;} 
        .m{display: none;}
    }
}
.arizona_sun{
    background: url('/pictures/four-horse-stalls.jpg') no-repeat center center fixed; ;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100%;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 25px;
    
}
.az_sun{
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}
footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100;
} 
.ig{
    width: 25%;
    height: auto;
    margin-left: 38% !important;
}
.shady{
    margin: 0% !important;
}
@media only screen and (min-width: $small) and (max-width: $medium){
    .l{display: none !important;}
    .arizona_sun{
        background: url('/pictures/four-horse-stalls.jpg') no-repeat top center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: 100%;
        display: flex; 
        flex-direction: row;}
    .horse-shade{
        margin-left: 12% !important;
        padding-left: 3% !important;
        padding-right: 12% !important;
    }
    .ig{
        margin-left: 10% !important;

    }

    .shady{
        margin-left: 33% !important;
        padding-left: 0% !important;
    } 
    .resize{
        width: 200px !important; 
        height: auto;
        margin-left: auto !important;
    }
    .image-gallery {
        width: 110%;
        height: auto;
    }
    .contact_nav_bar{
        flex-direction: row !important;
        align-items: center;
        a{
            margin: 0% !important;
        }
    }
    .image-gallery-slide img {
        width: 100%;
        height: auto;
        max-height: 90vh;
        object-fit: cover;
        overflow: hidden;
        object-position: center center;
    }
    
    .fullscreen .image-gallery-slide img {
        max-height: 100vh;
    } 
    .mScreen{
        display: flex !important;
        flex-direction: row;
        justify-content: center !important;
        margin-left: 26px !important;
        margin-right: 0% !important;   
    } 
    #m-l{
        margin-left: 0% !important;
        margin-right: 50% !important;
    }
}
.AzHorseShade{
    background-image: url($rustic) !important;
} 
.horse_shade_roof{
    background-image: url($cpanel);
    background-repeat: repeat;
    background-size: auto;
    height: auto;
    max-height: 60vh;
    overflow: hidden;
    @media only screen and (min-width: $small) and (max-width: $medium){ 
     
    }

} 
div.App{
    background-image: url($cpanel);
    background-repeat: repeat;
    background-position: center;
    background-size: 200px;
} 
.az_gravel{
    background: url($gravel) no-repeat bottom center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    width: auto !important;
    height: 100vh !important; 
}
.az_sunny{
    background: url($sun) no-repeat top center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    width: auto !important;
    height: 100vh !important; 
    @media only screen and (min-width: $small) and (max-width: $medium){
        background: url($sun) no-repeat top right fixed;
        width: 100vh !important; 
        height: auto !important;

        display: flex;
        flex-direction: column !important;
        .targetSmall{
            display: block !important;
            
        } 
        .smaller{
            width: 300px !important; 
            height: auto !important;
        }
        .l{
            margin-left: 15px !important; 
            padding-left: 0% !important;
        }
    }
}

header {
    letter-spacing: 1rem;
    text-transform: uppercase;
    font-size: 5rem;
    text-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.342);
}

.contact{
    height: 35vh;
    background-image:  linear-gradient(to right, rgba(54, 214, 62, 0.794), rgba(53, 201, 157, 0.794), rgba(32, 155, 212, 0.794)),url("../pictures/10X20-Shade-4Rail-Stalls.jpg");
    background-position: center;
}

@media only screen and (min-width: $small) and (max-width: $medium){
    nav.shady.contact_nav_bar{
        margin-left: 0% !important;
    }
    .s{display: none;}
    div.az_sun{ 
        margin-left: 0% !important;
    }
    .shrink{ 
    display: flex;
    flex-direction: row-reverse !important;
    margin-left: 0% !important; 
    padding-left: 0% !important;
    a{ 
        margin: 0% !important;
        margin-left: 0% !important;
        padding: 0% !important;
    }
}    
}   